import {
  createMemo,
  createContext,
  ParentProps,
  useContext,
  onMount,
} from "solid-js";

import { getTranslator, Translator } from "#root/locale";
import { SupportedLocale } from "#root/locale";
import { initializeDateLocale } from "#root/module/date";

const TranslationContext = createContext<{
  t: Translator;
  locale: SupportedLocale;
}>();

export function TranslationProvider(
  props: ParentProps<{ locale: SupportedLocale }>,
) {
  const t = createMemo(() => getTranslator(props.locale as SupportedLocale));

  onMount(() => {
    initializeDateLocale(props.locale);
  });

  return (
    <TranslationContext.Provider
      value={{
        t: t(),
        locale: props.locale,
      }}
    >
      {props.children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  const context = useContext(TranslationContext);
  if (context === undefined)
    throw Error(
      "TranslationContext is not used within provider TranslationProvider",
    );

  return context;
}
