import { format, setDefaultOptions } from "date-fns";
import TimeAgo from "javascript-time-ago";

import { SupportedLocale } from "#root/locale";

let timeAgo: TimeAgo;

export async function initializeDateLocale(locale: SupportedLocale) {
  switch (locale) {
    case "en":
      const { default: timeAgoEn } = await import(
        "javascript-time-ago/locale/en"
      );
      const { enUS: dateFnsEn } = await import("date-fns/locale/en-US");

      TimeAgo.addDefaultLocale(timeAgoEn);
      timeAgo = new TimeAgo("en-US");
      setDefaultOptions({ locale: dateFnsEn });
      break;
    case "fr":
      const { default: timeAgoFr } = await import(
        "javascript-time-ago/locale/fr"
      );
      const { fr: dateFnsFr } = await import("date-fns/locale/fr");

      TimeAgo.addDefaultLocale(timeAgoFr);
      timeAgo = new TimeAgo("fr");
      setDefaultOptions({ locale: dateFnsFr });
      break;
  }
}

export function formatDateFromISO(date: string) {
  return format(date, "PP");
}

export function getTimeAgo(date: string) {
  return timeAgo.format(new Date(date));
}
