import * as i18n from "@solid-primitives/i18n";

import accountEn from "./en/account.json";
import commonEn from "./en/common.json";
import creditsEn from "./en/credits.json";
import dashboardEn from "./en/dashboard.json";
import headEn from "./en/head.json";
import homeEn from "./en/home.json";
import loginEn from "./en/login.json";
import newSessionEn from "./en/newSession.json";
import overlayEn from "./en/overlay.json";
import privacyEn from "./en/privacy.json";
import sessionEn from "./en/session.json";
import settingsEn from "./en/settings.json";
import termsEn from "./en/terms.json";

import accountFr from "./fr/account.json";
import commonFr from "./fr/common.json";
import creditsFr from "./fr/credits.json";
import dashboardFr from "./fr/dashboard.json";
import headFr from "./fr/head.json";
import homeFr from "./fr/home.json";
import loginFr from "./fr/login.json";
import newSessionFr from "./fr/newSession.json";
import overlayFr from "./fr/overlay.json";
import privacyFr from "./fr/privacy.json";
import sessionFr from "./fr/session.json";
import settingsFr from "./fr/settings.json";
import termsFr from "./fr/terms.json";

export const SUPPORTED_LOCALES = ["fr", "en"] as const;
export const DEFAULT_LOCALE = SUPPORTED_LOCALES[1];

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];

export type LocaleResources = {
  account: typeof accountFr;
  common: typeof commonFr;
  credits: typeof creditsFr;
  dashboard: typeof dashboardFr;
  head: typeof headFr;
  home: typeof homeFr;
  login: typeof loginFr;
  newSession: typeof newSessionFr;
  overlay: typeof overlayFr;
  privacy: typeof privacyFr;
  session: typeof sessionFr;
  settings: typeof settingsFr;
  terms: typeof termsFr;
};

export type Translator = i18n.Translator<i18n.Flatten<LocaleResources>>;

const resources: Record<SupportedLocale, LocaleResources> = {
  en: {
    account: accountEn,
    common: commonEn,
    credits: creditsEn,
    dashboard: dashboardEn,
    head: headEn,
    home: homeEn,
    login: loginEn,
    newSession: newSessionEn,
    overlay: overlayEn,
    privacy: privacyEn,
    session: sessionEn,
    settings: settingsEn,
    terms: termsEn,
  },
  fr: {
    account: accountFr,
    common: commonFr,
    credits: creditsFr,
    dashboard: dashboardFr,
    head: headFr,
    home: homeFr,
    login: loginFr,
    newSession: newSessionFr,
    overlay: overlayFr,
    privacy: privacyFr,
    session: sessionFr,
    settings: settingsFr,
    terms: termsFr,
  },
};

export function getTranslator(locale: SupportedLocale) {
  const localizedResource = resources[locale];
  const flattenResource = i18n.flatten(localizedResource);

  return i18n.translator(() => flattenResource, i18n.resolveTemplate);
}

export function extractLocaleFromHeader(
  header: string | null,
): SupportedLocale | undefined {
  if (!header) return DEFAULT_LOCALE;

  const headerLngs = header.split(",").map((lng) => lng.split("_").at(0));
  return SUPPORTED_LOCALES.find((supportedLng) =>
    headerLngs.includes(supportedLng),
  );
}

export function extractLocaleFromPath(path: string) {
  const [_, lng, ...rest] = path.split("/");

  const locale = SUPPORTED_LOCALES.find((supportedLng) => lng === supportedLng);

  const urlWithoutLocale = "/" + rest.join("/");

  return { locale, urlWithoutLocale };
}

export function extractLocaleFromCookie(cookieString: string) {
  return SUPPORTED_LOCALES.find(
    (supportedLng) => cookieString === supportedLng,
  );
}
